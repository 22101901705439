/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateSubscription = /* GraphQL */ `
  mutation UpdateSubscription(
    $input: UpdateSubscriptionInput!
    $condition: ModelSubscriptionConditionInput
  ) {
    updateSubscription(input: $input, condition: $condition) {
      id
      email
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSubscription = /* GraphQL */ `
  mutation DeleteSubscription(
    $input: DeleteSubscriptionInput!
    $condition: ModelSubscriptionConditionInput
  ) {
    deleteSubscription(input: $input, condition: $condition) {
      id
      email
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createSubscription = /* GraphQL */ `
  mutation CreateSubscription(
    $input: CreateSubscriptionInput!
    $condition: ModelSubscriptionConditionInput
  ) {
    createSubscription(input: $input, condition: $condition) {
      id
      email
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
