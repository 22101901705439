/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:c61adb5d-186b-4021-8c3d-a9591a47b264",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_2Lww9kj84",
    "aws_user_pools_web_client_id": "1d181602mtn2bud4v2vh96etnl",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://ffc23pakczegdhe7acwq4wxu6q.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ch6lyk3tijg4tehgyrbioti6xi"
};


export default awsmobile;
