/* eslint-env jquery */
import React, { useState, useEffect } from 'react';
import HeaderSape from './assets/header-sape.png';
import SweetAppImage from './assets/sweetapp4.png';
import FocusPic from './assets/rocket.png';
import ConfidencePic from './assets/king.png';
import SelfAwarePic from './assets/mirror.png';
import ImpactfulPic from './assets/influence3.png';
import ResillientPic from './assets/atlas.png';
import PersonalPic from './assets/apw-Icon1.png';
import EasyPic from './assets/apw-Icon2.png';
import FunPic from './assets/apw-Icon3.png';
import AffordablePic from './assets/apw-Icon4.png';
import HappyPic from './assets/happy.png';
import CalmnessPic from './assets/calmness.png';
import { withAuthenticator } from 'aws-amplify-react';
import Parallax from 'parallax-js';
import Lottie from 'react-lottie';
import * as EmailValidator from 'email-validator';
import * as cheersAnimationData from './assets/cheers.json';

import {API, graphqlOperation} from 'aws-amplify';
import {createSubscription} from './graphql/mutations';

// function TrueEmail(props) {
//   const validEmail = props.validEmail;
//   if (validEmail) {
//     return <div>Valid Email</div>;
//   }
//   return <div>Invalid Email</div>;
// }


class App extends React.Component {

  state = {
    email: "",
    validEmail: false,
    isStopped: true,
    emailSubmitted: false,
  };

  // onClick = () => {
  //   console.log(this.state.email);
  // }

  onInputChange = (value) => {
    var pro = this.setState({email: value}, () => {
      this.setState({validEmail: EmailValidator.validate(this.state.email)});
    });
  }

  onClickJoin = async () => {
    const input = {
      email: this.state.email,
    }

    let result = await API.graphql({
      query: createSubscription,
      variables: {input},
      authMode: "API_KEY"
    });

    $('#bite-66').modal('show')

    this.setState({emailSubmitted: true});
        
    $('#bite-66').on('hidden.bs.modal', () => {
      console.log("close");
      this.setState({isStopped:true, validEmail: false});
    });

    this.setState({isStopped:false});
  }

  componentDidMount(){
    var scene = document.getElementsByClassName('parallax');
    var parallax = new Parallax(scene[0]);
    var parallax = new Parallax(scene[1]);
  }
  
  submitHandler = (e) => {
    e.preventDefault();
    console.log(e);
    if (this.state.validEmail){
      this.onClickJoin();
    }
}


  render () {
  const defaultOptions = {
    loop: false,
    autoplay: true, 
    animationData: cheersAnimationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return <main>
    <section className="slider-area slider-bg2 second-slider-bg d-flex fix" style={{backgroundImage: 'url(' + require('./assets/pink-header-bg.png') + ')', backgroundPosition: "right 0", backgroundRepeat: "no-repeat", backgroundSize: "65%"}} >
      <div className="slider-shape ss-one layer parallax">
      <img data-depth="0.20" src={HeaderSape} alt="shape" style={{zIndex: "-1 !important"}}></img>
      </div>
      <div className="container action-space">
        <div className="row">
          <div className="col-lg-6">
            <div className="slider-content second-slider-content left-center">
            <ul className="small-title mb-30">
              <li>New</li>
              <li>Lifestyle app</li>
            </ul>
            <h2 data-animation="fadeInUp" data-delay=".4s">Become the <span>sweetest</span> version of yourself</h2>
            <div className="mt-30 mb-30">
              {/* <a href="#" className="btn ss-btn" data-animation="fadeInUp" data-delay=".8s">Coming soon..</a> */}
              <form  onSubmit={this.submitHandler} className="contact-form">
                <div className="row">

                  <div className="col-lg-10">
                    { !this.state.emailSubmitted ? <span>Sign up to get an invitation:</span> : <span>You are in 🎉</span> }
                    
                    { !this.state.emailSubmitted ? <div className="contact-field p-relative c-name mb-20 mt-20">
                      <input className="email-input join-email" style={ this.state.validEmail ? {border: "2px solid #7FFF00"} : {border: "1px solid #FF3494"} } type="text" placeholder="Email" value={this.state.email || ""} onChange={event => this.onInputChange(event.target.value)}>
                      </input>
                      
                      <button type="button" disabled={this.state.validEmail ? false : true} className="btn join-button" data-animation="fadeInUp" data-delay=".8s" onClick={this.onClickJoin}>Join</button>
                    </div> : <div className="submitted-email"> {this.state.email}</div>}
                    {/* <TrueEmail validEmail={this.state.validEmail} /> */}
                    <span>Our early users will get a sweet gift 🎁</span>
                  </div>
                  
                </div>
              </form>
            </div>
            </div>
          </div>
          <div className="col-lg-6 parallax">
            <img data-depth="0.10" src={SweetAppImage} alt="shape" className="s-img" style={{zIndex: "1 !important"}}></img>
          </div>
        </div>
      </div>
    </section>

    <div className="modal fade position-center-center m-width-370" id="bite-66" bite-show="fadeIn" bite-hide="fadeOut">
      <div className="modal-dialog animated fast">
          <div className="modal-content">
              <div className="modal-header position-relative">
                  <Lottie options={defaultOptions}
                    height={300}
                    width={300}
                    isClickToPauseDisabled={true}
                    isStopped={this.state.isStopped}
                    isPaused={this.state.isPaused}/>
              </div>
              <div className="modal-body bg-white text-center">
                  <div className="popup-title">Cheers to a sweet life!</div>
                  <p className="popup-text" style={{color: "#FF3494"}}>{this.state.email} ✅</p>
                  <p className="popup-text">We are glad to have you with us. We will send you a special invitation when we launch! </p>
                  <p className="popup-text">As a token of our appreciation for being our early user, you will receive a special gift accompanying the invitation!</p>
                  <p className="popup-text">Reach out to us <a style={{color: "#FF3494"}} href="mailto:hi@sweet.app">here</a> if you have requests or ideas for us. We can't wait to help you on your self-improvement journey 😇</p>

              </div>


              
              
          </div>
      </div>
  </div>

    <section id="about" className="services-area services-bg pt-25 pb-20" style={{backgroundImage: 'url(' + require('./assets/header-sape2.png') + ')', backgroundPosition: "right top", backgroundRepeat: "repeat-y", backgroundSize: "auto"}} >
      <div className="container content-div">

        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-10">
            <div className="section-title text-center pl-40 pr-40 mb-45">
              <h2>Discover our programs</h2>
              <p>🚀 Use our programs and design your growth plan <br></br> 🤖 All programs are personalized via a robo-advisor <br></br> 📅 Get fresh and creative action items daily</p>
            </div>
          </div>
        </div>
        
        <div className="row justify-content-center">

          <div className="col-lg-4 col-md-12 mb-30">
            <div className="s-single-services active text-center">
              <div className="services-icon">
                <img src={FocusPic} height="128px"></img>
              </div>
              <div className="second-services-content">
                <h5>Focus</h5>
                <p>Only through focus can you do world-class things. -Bill Gates</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-12 mb-30">
            <div className="s-single-services active text-center">
              <div className="services-icon">
              <img src={ConfidencePic} height="128px"></img>
              </div>
              <div className="second-services-content">
                <h5>Confidence</h5>
                <p>Believe you can and you're halfway there. ―Theodore Roosevelt</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-12 mb-30">
            <div className="s-single-services active text-center">
              <div className="services-icon">
                <img src={HappyPic} height="128px"></img>
              </div>
              <div className="second-services-content">
                <h5>Happiness</h5>
                <p>In bad times and good, I have never lost my sense of zest for life. -Walt Disney</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-12 mb-30">
            <div className="s-single-services active text-center">
              <div className="services-icon">
              <img src={CalmnessPic} height="128px"></img>
              </div>
              <div className="second-services-content">
                <h5>Calmness</h5>
                <p>The best fighter is never angry. -Lao Tzu</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-12 mb-30">
            <div className="s-single-services active text-center">
              <div className="services-icon">
                <img src={ResillientPic} height="128px"></img>
              </div>
              <div className="second-services-content">
                <h5>Resillience</h5>
                <p>Life is going to hit you in the head with a brick. Don't lose faith. -Steve Jobs</p>
              </div>
            </div>
          </div>
          
        </div>

      </div>
    </section>
    <section id="features" className="app-work pt-70 pb-100 p-relative" style={{backgroundImage: 'url(' + require('./assets/header-sape2.png') + ')', backgroundPosition: "right top", backgroundRepeat: "repeat-y", backgroundSize: "auto"}}>            
                <div className="container why-div">
                    <div className="row align-items-center justify-content-center">
                       
                        <div className="col-xl-6">
                            <div className="choose-wrap">
                                <div className="section-title w-title text-center mb-15">
                                    <h2>Why?</h2>
                                    <h4>Because self-improvement should be..</h4>
                                </div>
                                <div className="app-work-content mt-20">
                                    <ul>
                                        <li>
                                            <div className="icon"><img src={EasyPic}></img></div> 
                                            <div className="text">
                                                <h4>Easy</h4>
                                                <p>You don't need to read pages of books, watch hours of video courses and then jot down the action-items. We handle that for you. Your self-improvement journey starts with a click!</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon"><img src={PersonalPic}></img></div> 
                                            <div className="text">
                                                <h4>Personal</h4>
                                                <p>Our robo-advisors ask simple questions before you start any program. This way you get the best action-items that suit your lifestyle.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon"><img src={FunPic}></img></div> 
                                            <div className="text">
                                                <h4>Fun</h4>
                                                <p>Generic advice is boring. "If you want to be more confident, do self-affirmations". We wouldn't do that to you. We give you creative and fun-to-do items.</p>
                                            </div>
                                        </li>
                                        {/* <li>
                                            <div className="icon"><img src={AffordablePic}></img></div> 
                                            <div className="text">
                                                <h4>Affordable</h4>
                                                <p>Personal coaches cost a fortune. Self-help books and courses are pricey. Our app will be best friends with your bank account.</p>
                                            </div>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
  </main>
  }}

export default App;
